import Vue from 'vue'
import Vuex from 'vuex'
import Industries from './industries'
import Categories from './categories'
import DataTypes from './data-types'
import Products from './products'
import Downloads from './downloads'
import Cart from './cart'
import Order from './order'
import DownloadRequest from './download-request'
import Taggroup from './taggroup'

Vue.use(Vuex)

const state = () => ({
  privacylink: window.app.download_list.privacyLink,
  initialized: false,
  fixedProduct: window.app.download_list.productId > 0 ? window.app.download_list.productId : 0,
  fixedDataType: window.app.download_list.dataTypes.length,
  fixedIndustry: window.app.download_list.industries.length,
  fixedCategory: window.app.download_list.categories.length,
  productCategory: window.app.download_list.productCategory,
  errors: [],
  showTags: window.app.download_list.showTags,
  selectedTaggroup: window.app.download_list.taggroup,
  keywordfilter: ''
})

const getters = {}

const mutations = {
  setInitialized (state, payload) {
    state.initialized = payload
  },
  setErrors (state, payload) {
    state.errors.push(payload)
  },
  setFixedProduct (state, payload) {
    state.fixedProduct = payload
  },
  setKeywordFilter (state, payload) {
    state.keywordfilter = payload
  }
}

const actions = {
  init ({ dispatch, commit, state }) {
    if (state.showTags !== '0' && state.selectedTaggroup !== '-1') {
      Promise.all([
        dispatch('taggroup/get', state.selectedTaggroup),
        dispatch('downloads/prefetchValidIds'),
        dispatch('downloads/get'),
      ]).then(() => {
        commit('setInitialized', true)
      })
    } else {
      Promise.all([
        dispatch('industries/get'),
        dispatch('categories/get'),
        dispatch('dataTypes/get'),
        dispatch('products/get'),
        dispatch('downloads/prefetchValidIds'),
        dispatch('downloads/get'),
      ]).then(() => {
        commit('setInitialized', true)
      })
    }
  },
  clearKeywordsearch ({commit}) {
    commit('setKeywordFilter', '')
  },
  filter ({ dispatch, commit }) {
    commit('downloads/resetCurrentPage')

    Promise.all([
      dispatch('downloads/get'),
    ])
  },
  loadMore ({ commit, dispatch }) {
    commit('downloads/increaseCurrentPage')
    Promise.all([
      dispatch('downloads/get')
    ])
  }
}

const modules = {
  industries: Industries,
  categories: Categories,
  dataTypes: DataTypes,
  products: Products,
  downloads: Downloads,
  cart: Cart,
  order: Order,
  downloadRequest: DownloadRequest,
  taggroup: Taggroup,
}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules
})
