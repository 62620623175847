<template>
  <div class="module-overlay module-overlay--default" :class="{'-open': show}">
    <div class="overlay__overlay">
      <div class="overlay__background"/>
      <div class="overlay__content">
        <div class="overlay__close" @click="closePrefetchOverlay"/>
        <div class="content-wrapper">
         <div class="content-top">
           <div class="content-top-intro">
             <span class="meta">{{ $t('prefetchOverlay.intro') }}</span>
           </div>
           <div class="content-top-items">
             <div class="content-top-item" v-for="item in items" :key="item.uid">
               <span class="epsilon bold">{{item.name}}</span>
             </div>
           </div>
         </div>
          <div class="content-actions">
            <div class="action-cart">
              <div class="icon-box">
                <span class="icon-cart-alternative-before"></span>
              </div>
              <span class="bold">{{ $t('prefetchOverlay.cart.text') }}</span>
              <div class="btn -btn-alpha" @click="triggerDownloadOverlay"><span>{{ $t('prefetchOverlay.cart.btn') }}</span></div>
            </div>
            <div class="action-back">
              <div class="icon-box">
                <span class="icon-file-before"></span>
              </div>
              <span class="bold">{{ $t('prefetchOverlay.back.text') }}</span>
              <div class="btn -btn-alpha" @click="closePrefetchOverlay"><span>{{ $t('prefetchOverlay.back.btn') }}</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'DownloadPrefetchOverlay',
  props: {
    show: {
      type: Boolean,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    triggerDownloadOverlay() {
      this.$emit('show-mobile-download-overlay');
    },
    closePrefetchOverlay() {
      this.$emit('close-prefetch-overlay');
    }
  }
}
</script>
