import axios from 'axios'
import Cart from './cart'

const state = () => ({
  loaded: false,
  items: [],
  currentPage: 1,
  itemsPerPage: 9,
  numberOfPages: 1,
  numberOfTotalItems: 9,
  preFetchedItems: []
})

const getters = {
  getParams: (state, getters, rootState) => {
    const params = {
      currentPage: state.currentPage,
      itemsPerPage: state.itemsPerPage,
      industries: {},
      categories: {},
      dataType: {},
      products: {},
    }
    if (rootState.industries.selected.length) {
      params.industries.contains = rootState.industries.selected.toString()
    }
    if (rootState.categories.selected.length) {
      params.categories.contains = rootState.categories.selected.toString()
    }
    if (rootState.dataTypes.selected.length) {
      params.dataType.in = rootState.dataTypes.selected.toString()
    }
    if (rootState.products.selected.length) {
      params.products.contains = rootState.products.selected.toString()
    }
    if (rootState.productCategory.uid) {
      params.products_category.equals = rootState.productCategory.uid
    }
    if (rootState.keywordfilter) {
      params.dnumber = {
        like: '%' + rootState.keywordfilter.toLowerCase() + '%'
      }
    }
    return params
  },
  getPrefetchParams: () => {
    const params = {
      variants: {},
    }

    const url = new URL(window.location.href)
    const documentIds = url.searchParams.get('document-id')
    let validDocumentIds = ''

    if (documentIds) {
      validDocumentIds = documentIds
        .split(',')
        .filter(id => /^\d+$/.test(id))
        .join(',');
    }

    params.variants.contains = validDocumentIds

    return params
  }
}

const mutations = {
  setLoaded (state, payload) {
    state.loaded = payload
  },
  setItems (state, payload) {
    state.items = [...payload]
  },
  addItems (state, payload) {
    state.items = [...state.items, ...payload]
  },
  resetCurrentPage (state) {
    state.currentPage = 1
  },
  increaseCurrentPage (state) {
    state.currentPage += 1
  },
  setNumberOfPages (state, payload) {
    state.numberOfPages = payload
  },
  setNumberOfTotalItems (state, payload) {
    state.numberOfTotalItems = payload
  },
  setPrefetchedItems (state, payload) {
    state.preFetchedItems = [...payload]
  },
}

const actions = {
  async get ({ state, commit, getters }) {
    commit('setLoaded', false)
    const resetPage = (state.currentPage === 1)
    if (resetPage) commit('setItems', [])
    try {
      const response = await axios.get(window.app.download_list.api_base + 'downloads/', { params: { ...getters.getParams } })
      resetPage
          ? commit('setItems', response.data.downloads)
          : commit('addItems', response.data.downloads)
      commit('setNumberOfPages', response.data.numberOfPages)
      commit('setNumberOfTotalItems', response.data.numberOfTotalItems)
      commit('setLoaded', true)
      return response
    } catch (e) {
      commit('setErrors', e, { root: true })
    }
  },
  async prefetchValidIds ({ dispatch, commit, getters }){
    try {
      const response = await axios.get(window.app.download_list.api_base + 'downloads/', { params: { ...getters.getPrefetchParams } })
      commit('setPrefetchedItems', response.data)
      const items = response.data
      items.forEach(item => {
        const variant = Object.values(item.variants)[0] || {};  // This works even if variants is an object
        const download = item
        dispatch('cart/addItem', { variant, download, initial: true } , { root: true });
      });
      return response
    } catch (e) {
      commit('setErrors', e, { root: true })
    }
  }
}

const modules = {
  cart: Cart,
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules
}
